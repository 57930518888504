import React, { useState, useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Controller } from 'react-scrollmagic'
import { Link } from 'gatsby'
import { useTheme } from 'styled-components'

import imgTickets from '../assets/img-tickets.webp'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Dropdown from '../components/Dropdown'
import Footer from '../components/Footer'

import { PageLayout } from '../styles/Layout'
import { FestivalAtoZStyled } from '../styles/FestivalAtoZStyled'
import { TitleS, BodyM } from '../styles/Typography'

const FestivalAToZ = () => {
  const theme = useTheme()

  const [isReverse, setIsReverse] = useState(false)

  const changeBackgroundColor = () => {
    const windowScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const pageHeight = document.body.offsetHeight - (window.innerHeight + 300)

    if (windowScroll >= pageHeight) {
      setIsReverse(true)
    } else {
      setIsReverse(false)
    }
  }

  const onScroll = () => {
    window.requestAnimationFrame(changeBackgroundColor)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <SEO
        title="Butik | Festival A to Z"
        description="Butik Electronic Music Festival"
        image={imgTickets}
      />
      <PageLayout
        backgroundColor={
          isReverse ? theme.backgroundDark : theme.backgroundPrimary
        }
      >
        <Header
          textColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
          logoColor={isReverse ? theme.backgroundPrimary : theme.backgroundDark}
        />
        <FestivalAtoZStyled>
          <div className="content">
            <Controller>
              <div className="inner" id="dropdown-list">
                <TitleS className="title">Butik Festival from A to Z</TitleS>
                <BodyM className="text">
                  Here you can find answers to most of your questions about how
                  things work at the Butik Festival. Please read through before
                  attending the festival (or even before buying the ticket) to
                  avoid any misunderstanding and to properly prepare for the
                  best festival experience. Some things are not fixed yet, we
                  will be updating this regularly.
                </BodyM>
                <BodyM className="text">
                  If you still have questions, don&#39;t hesitate to contact us
                  through social media or send an email to{' '}
                  <a href="mailto:info@butikfestival.com">
                    info@butikfestival.com
                  </a>
                  .
                </BodyM>

                <div className="list">
                  <Dropdown title="ADDRESS" id="address">
                    <BodyM className="item-text">
                      Festival site address is Dijaška 18, 5220 Tolmin,
                      Slovenia.{' '}
                      <a
                        href="https://goo.gl/maps/rivkdDpxbyLB3DWGA"
                        target="_blank"
                      >
                        Click here
                      </a>{' '}
                      to see it on Google maps.
                    </BodyM>
                    <BodyM className="item-text">
                      For box office location, please{' '}
                      <a
                        href="https://www.google.com/maps/search/46.178923,+13.724849?entry=tts&g_ep=EgoyMDI0MDcwNy4xKgBIAVAD"
                        target="_blank"
                      >
                        click here
                      </a>
                      .
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="AWARENESS" id="awareness">
                    <BodyM className="item-text">
                      We want Butik Festival to be a lovely experience for
                      everyone, so we encourage attentive, respectful and
                      conscious interactions. Butik festival is supposed to be a
                      place where everybody is welcome and accepted. To read
                      more <Link to="/awareness">click here</Link>.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="CAMPERVANS" id="campervans">
                    <BodyM className="item-text">
                      There is a special part of the campsite dedicated for
                      campervans.
                    </BodyM>
                    <BodyM className="item-text">Campervan parking fees:</BodyM>
                    <ul>
                      <li>campervan - regular parking 80€ (full festival)</li>
                      <li>
                        campervan - parking with electricity 105€ (see{' '}
                        <a onClick={() => scrollTo('#electricity')}>
                          ELECTRICITY
                        </a>{' '}
                        for more details).
                      </li>
                    </ul>
                    <BodyM className="item-text">
                      You can get a campervan parking ticket on our{' '}
                      <a href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0/tickets">
                        webshop
                      </a>
                      . The number of campervan parkings with electricity is
                      limited.
                    </BodyM>
                    <BodyM className="item-text">
                      Please note that the campervan area is separate from the
                      tent area. You can set up a shade in front of your
                      campervan, but you cannot camp together with friends who
                      have tents.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="CAMPING" id="camping">
                    <BodyM className="item-text">
                      The camping is open from <b>Wednesday 09.07.2025, 3PM</b>,
                      until <b>Monday, 14.07.2025, 10AM</b>. Camping from
                      Thursday till Monday is included in every festival ticket.
                      If you want to arrive already on Wednesday see{' '}
                      <a onClick={() => scrollTo('#early_arrival')}>
                        EARLY ARRIVAL
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      You're allowed to bring food and drinks to the campsite
                      but not to the festival area see{' '}
                      <a onClick={() => scrollTo('#prohibited_objects')}>
                        PROHIBITED OBJECTS
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      Petrol power generators are not allowed in the camping
                      area.
                    </BodyM>
                    <BodyM className="item-text">
                      There are cold showers in the camping, for hot showers,
                      see{' '}
                      <a onClick={() => scrollTo('#hot_showers')}>
                        HOT SHOWER AND FLUSH TOILETS
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      There is drinkable tap water in the campsite, see{' '}
                      <a onClick={() => scrollTo('#water')}>WATER</a>.
                    </BodyM>
                    <BodyM className="item-text">
                      Half of the camping area is located under the trees and
                      half is in a place with no shade. Keep in mind that it can
                      also get a bit chilly during the night, so bring some warm
                      clothes and a sleeping bag.
                    </BodyM>
                    <BodyM className="item-text">
                      Cars are not allowed in the camping area, you can park
                      them in a big parking lot that is located right next to
                      the campsite, see{' '}
                      <a onClick={() => scrollTo('#parking')}>PARKING</a>.
                    </BodyM>
                    <BodyM className="item-text">
                      No music is allowed in the campsite after 1 AM. Many
                      people prefer to party during the day and rest at night.
                      Another reason is that we are not permitted to play loud
                      music outdoors after 1 AM. Failure to comply with this
                      rule could lead to losing the festival location. You might
                      think that your sound system cannot produce that much
                      noise, but you would be surprised how far it can travel.
                      Thank you for respecting this rule.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="DAYTIME ACTIVITIES" id="daytime_activities">
                    <BodyM className="item-text">
                      At Butik Festival, we offer a variety of daytime
                      activities to keep you engaged and refreshed. More info
                      will be announced in the festival app and on our social
                      media.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="EARLY ARRIVAL" id="early_arrival">
                    <BodyM className="item-text">
                      Early arrival at Butik Festival is possible from
                      Wednesday, 09.07.2024 at 3 PM. The camp will be open and
                      ready to welcome the first visitors. However, an early
                      arrival pass will be required for this extra day of
                      camping and music program.
                    </BodyM>
                    <BodyM className="item-text">
                      Do note that not all bars and food venues will be
                      operational on this early arrival day. And while we're
                      dedicated to getting everything picture-perfect for the
                      festival, there might be some last-minute production work
                      underway throughout the festival area.
                    </BodyM>
                    <BodyM className="item-text">
                      There will be one stage operating on Wednesday and it will
                      run until 1AM.
                    </BodyM>
                    <BodyM className="item-text">
                      You can get an early arrival pass on our{' '}
                      <a href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0/tickets">
                        webshop
                      </a>
                      .
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="ENTRANCE" id="entrance">
                    <BodyM className="item-text">
                      When you arrive at the festival you need to exchange your
                      ticket for a full festival wristband at the box office.
                      Please note, there will be no name checks on the tickets.
                    </BodyM>
                    <BodyM className="item-text">
                      To arrive at the entrance location, please follow
                      navigation to{' '}
                      <a
                        href="https://maps.app.goo.gl/AjNiQRoabbk833Zp9"
                        target="_blank"
                      >
                        this place
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      Once you get your wristband, you can access the festival
                      site from any direction.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="ELECTRICITY FOR CAMPERVANS" id="electricity">
                    <BodyM className="item-text">
                      Our festival provides electricity hookups exclusively for
                      campervans.
                    </BodyM>
                    <BodyM className="item-text">
                      You can get a campervan parking with electricity on our{' '}
                      <a href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0/tickets">
                        webshop
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      Electricity technical info:
                    </BodyM>
                    <ul>
                      <li>230V, 6A each</li>
                      <li>Every outlet has its own RCD</li>
                    </ul>
                    <BodyM className="item-text">
                      Upon arrival at the parking please inform the staff at the
                      entrance if you require assistance with your electricity
                      hookup. They will provide you with the necessary
                      instructions and the technician's contact number. Ensure
                      you have your campervan with an electricity ticket ready.
                      You will need to call the technician for the hookup
                      process or in case of any issues.
                    </BodyM>
                    <BodyM className="item-text">
                      The technician is available from 8.00 until 22:00
                    </BodyM>
                    <BodyM className="item-text">
                      Users need to have their own cable, we suggest 50m (better
                      even more!)
                    </BodyM>
                    <BodyM className="item-text">
                      Please note that petrol/diesel power generators are NOT
                      ALLOWED in the camp. The noise is disturbing other campers
                      and also inhabitants of Tolmin who live nearby the
                      campsite. Being respectful to the local community is very
                      important in order to keep this festival location.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="EXTRA ACTIVITIES IN SOČA VALLEY"
                    id="extra_activities"
                  >
                    <BodyM className="item-text">
                      The Soča Valley is a paradise for nature lovers, water
                      sports enthusiasts, and adrenaline seekers. We're creating
                      a brochure with top activities in the area—more details
                      coming soon!
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="FESTIVAL APP" id="festival_app">
                    <BodyM className="item-text">TBA</BodyM>
                  </Dropdown>
                  <Dropdown title="FESTIVAL FUND" id="festival_fund">
                    <BodyM className="item-text">
                      Butik Festival, in collaboration with Punk Rock Holiday
                      and Tolminator festival, takes pride in contributing to
                      our local community through the "Festival Fund"
                      initiative. This is where we donate all unspent money left
                      on paycards after the festival to support various NGOs
                      chosen by our local community.
                    </BodyM>
                    <BodyM className="item-text">
                      From 2022 we were able to donate over 37,000€, supporting
                      a diverse array of causes - from rescue dogs, the local
                      football club, breast cancer awareness organization,
                      firefighters, association for helping people with
                      disabilities, model gliders to humanitarian organizations
                      for the underprivileged.
                    </BodyM>
                    <BodyM className="item-text">
                      When we say "we donated", we're acknowledging that you,
                      the People of Butik, are a vital part of this initiative.
                      Your leftover paycard funds are now actively helping these
                      organizations, so thank you for joining us in this
                      initiative last year.
                    </BodyM>
                    <BodyM className="item-text">
                      The Festival Fund initiative is something that brings us
                      immense joy, seeing the positive impact it has on so many
                      lives. Rest assured, we will continue this tradition in
                      future events, spreading smiles and making a difference
                      where it matters most.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="FESTIVAL MAP" id="festival_map">
                    <BodyM className="item-text">
                      To get a better understanding of the festival site, you
                      can{' '}
                      <a
                        href="https://butikfestival.com/Butik-festival-map-main-areas.pdf"
                        target="_blank"
                      >
                        check this map
                      </a>
                      . A detailed festival map will be available in the
                      festival app.
                    </BodyM>
                    <BodyM weight={600} className="item-text">
                      IMPORTANT: As of 2024, the venue layout has changed
                      slightly due to the new bypass road. Please check the
                      updated entrance and parking locations to avoid any
                      confusion on arrival.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="FESTIVAL TAX OF TOLMIN MUNICIPALITY"
                    id="festival_tax"
                  >
                    <BodyM className="item-text">
                      Since 2024, our festival has been required to pay a
                      festival tax to the Municipality of Tolmin, which is now
                      included in the ticket price.
                    </BodyM>
                    <BodyM className="item-text">
                      This tax was introduced through a decree that also secured
                      Butik festival's permit at its current location until
                      2027. This doesn't mean that Butik will only continue
                      until 2027; rather, the municipality will reassess the
                      conditions then and decide whether to extend or modify
                      them.
                    </BodyM>
                    <BodyM className="item-text">
                      A portion of the festival tax is reinvested directly into
                      the festival site, while another part supports local
                      projects and infrastructure in nearby villages, helping to
                      offset some of the festival's impact. We hope this tax
                      will have a positive effect on the local community.
                    </BodyM>
                    <BodyM className="item-text">
                      With other community-friendly measures that we
                      implemented—such as establishing a festival fund,
                      providing beach access for locals, and working to reduce
                      noise pollution—we would like to have a positive impact on
                      the environment and remain in Tolmin for many years to
                      come.
                    </BodyM>
                    <BodyM className="item-text">
                      We'd like to thank our guests, past and future, who value
                      this unique experience and show respect for nature,
                      locals, and each other. Let's keep Butik here together,
                      forever.
                    </BodyM>
                    <BodyM className="item-text">
                      {' '}
                      <a
                        href="https://www.tolmin.si/objava/900547"
                        target="_blank"
                      >
                        (click here to see official news about tax)
                      </a>
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="FIRST AID" id="first_aid">
                    <BodyM className="item-text">
                      For your safety and well-being, we provide a First Aid
                      Point that operates 24 hours a day during the festival.
                      The First Aid Point is more than just for emergencies,
                      it's there to make sure your health needs are taken care
                      of throughout the festival. So don't hesitate to drop by
                      if you have any situation that requires medical
                      assistance.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="FOOD AND DRINKS" id="food_and_drinks">
                    <BodyM className="item-text">
                      At Butik Festival, you&#39;ll find an assortment of food
                      stands, ready to cater to your varying dietary needs. Our
                      food vendors are providing quality meals, including
                      options for vegetarian, vegan, and gluten-free diets.
                    </BodyM>
                    <BodyM className="item-text">
                      Water is essential, especially during the festival. To
                      ensure you stay hydrated, there is drinkable tap water in
                      the campsite as well as at the festival site, where you
                      can refill your bottles.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="GARBAGE DEPOSIT" id="garbage_deposit">
                    <BodyM className="item-text">
                      Please take a minute and read how garbage deposit works
                      and why it is important.
                    </BodyM>
                    <BodyM className="item-text">
                      The garbage deposit is a system implemented at Butik
                      Festival where campsite attendees are required to provide
                      a monetary deposit upon arrival. This deposit serves as an
                      incentive for festival-goers to properly dispose of their
                      waste by returning full garbage bags to designated points.
                      By implementing the garbage deposit, the festival aims to
                      maintain a clean and litter- free environment, preserving
                      the natural beauty of the festival and its surroundings.
                    </BodyM>
                    <ol>
                      <li>
                        <b>How does it work?</b>
                        <br /> The garbage deposit is required for{' '}
                        <b>campsite attendees</b> at Butik Festival.
                        <br />
                        <ul>
                          <li>
                            Upon arrival, campers will receive 2 trash bags.
                          </li>
                          <li>
                            10€ will be deducted from your PayCard for the
                            garbage deposit
                          </li>
                          <li>
                            Return full garbage bags to designated points to
                            receive a 10€ reload on your PayCard. Check out{' '}
                            <a
                              onClick={() => scrollTo('#garbage_center_hours')}
                            >
                              GARBAGE CENTER OPENING HOURS
                            </a>
                            .
                          </li>
                        </ul>
                      </li>
                      <li>
                        <b>What if I am not staying at the campsite?</b>
                        <br /> If you're staying elsewhere, present your
                        reservation proof upon arrival to avoid the garbage
                        deposit. Proactively share your accommodation details
                        and proof to prevent any misunderstanding.
                      </li>
                      <li>
                        <b>Why is this so important?</b>
                        <br /> Butik Festival takes place in a beautiful
                        location on the edge of Triglav National Park.
                        <br />
                        To preserve the festival and campsite areas, it's
                        crucial to prevent them from becoming a landfill.
                        <br />
                        Maintaining cleanliness enhances the festival experience
                        and ensures a positive relationship with the local
                        community, who are strict about keeping nature clean.
                      </li>
                    </ol>
                  </Dropdown>
                  <Dropdown title="GETTING THERE" id="getting_there">
                    <BodyM className="item-text">
                      Enter this location in Google Maps as your final
                      destination to the entrance:{' '}
                      <a
                        href="https://maps.app.goo.gl/pRBb4zfQjDXNF4eq9"
                        target="_blank"
                      >
                        https://maps.app.goo.gl/pRBb4zfQjDXNF4eq9
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      Here are a few ways you can reach us:
                    </BodyM>
                    <BodyM className="item-text">
                      By Bus: For Slovenian buses, please visit{' '}
                      <a href="https://www.ap-ljubljana.si/en" target="_blank">
                        this site
                      </a>
                      . The final stop is Tolmin, which is within walking
                      distance to the festival site. The bus route is direct.
                    </BodyM>
                    <BodyM className="item-text">
                      By Train: For Slovenian trains, check out{' '}
                      <a href="https://potniski.sz.si/en/" target="_blank">
                        Slovenske Železnice
                      </a>
                      . The final station is Most na Soči. From there, you will
                      need to either take a taxi to the festival site or catch a
                      bus for a short 10-minute ride.
                    </BodyM>
                    <BodyM className="item-text">
                      By Airport Shuttle: We have an airport shuttle service
                      available. Please refer to our{' '}
                      <a onClick={() => scrollTo('#shuttle_service')}>
                        SHUTTLE SERVICE
                      </a>{' '}
                      section for more details.
                    </BodyM>
                    <BodyM className="item-text">
                      By Car: If you&#39;re driving from Ljubljana, we recommend
                      taking the route via Nova Gorica (more highway) or Idrija
                      (windy road). Please refer to our{' '}
                      <a onClick={() => scrollTo('#parking')}>PARKING</a>{' '}
                      section for information on where to park your car.
                    </BodyM>
                    <BodyM className="item-text">
                      As a sustainable initiative, if you don&#39;t have all
                      seats occupied, we encourage you to offer a ride to other
                      festival-goers in{' '}
                      <a
                        href="https://www.facebook.com/groups/1276504022504999"
                        target="_blank"
                      >
                        this Facebook group
                      </a>
                      . Not only does this save money and protect the
                      environment, but it's also a great opportunity to make new
                      friends!
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="HOT SHOWERS AND FLUSH TOILETS"
                    id="hot_showers"
                  >
                    <BodyM className="item-text">
                      Besides cold showers and chemical toilets, the festival
                      also offers a sanitary center with hot showers and flush
                      toilets. Since there is very little permanent
                      infrastructure on-site, we cannot provide this service for
                      all festival-goers. This is why the sanitary center is
                      available at extra cost.
                    </BodyM>
                    <BodyM className="item-text">
                      Whether you desire one-time access or a festival pass, you
                      can conveniently purchase your sanitary center pass
                      directly at the sanitary center itself during the
                      festival.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="IMPAIRED PEOPLE - WHEELCHAIR USERS"
                    id="wheelchair_users"
                  >
                    <BodyM className="item-text">
                      Wheelchair users or other physically handicapped people
                      who rely on support can bring 1 escorting person to the
                      festival area for free. An official medical certificate
                      will be demanded upon your arrival. Please note that due
                      to the rough terrain the beach stages are difficult to
                      approach.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="INFO POINT" id="info_point">
                    <BodyM className="item-text">
                      Head to the info point if you need any kind of
                      information, for lost & found, for rent-a-tent service,
                      lockers or phone charging.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="LOCKERS" id="lockers">
                    <BodyM className="item-text">
                      There are lockers available to rent at the festival.
                    </BodyM>
                    <BodyM className="item-text">
                      Lockers equipped with an electricity plug for device
                      charging can be pre-ordered or obtained at the festival's
                      info point. Given the limited number of lockers, we
                      recommend pre-ordering to guarantee availability. While we
                      typically have enough lockers to meet demand, it's best to
                      secure yours in advance to be on the safe side.
                    </BodyM>
                    <BodyM className="item-text">
                      They will be located near the info point in the campsite
                      area. There are two sizes available:
                    </BodyM>
                    <ul>
                      <li>Small (14 cm x 14 cm x 50 cm), 20€</li>
                      <li>Large (28 cm x 14 cm x 50 cm), 40€</li>
                    </ul>
                    <BodyM className="item-text">
                      You can only rent the locker for the duration of the
                      entire festival (Wednesday 3PM - Monday 10AM).
                    </BodyM>
                    <BodyM className="item-text">
                      To access your locker, collect the keys from the info
                      point. Please note that a key deposit of 10€ is required,
                      which will be refunded upon the return of the key.
                    </BodyM>
                    <BodyM className="item-text">
                      For pre-orders please{' '}
                      <a href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0/tickets">
                        click here
                      </a>
                      .
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="LOST AND FOUND" id="lost_and_found">
                    <BodyM className="item-text">
                      If you find any items, please leave them at the festival's
                      info point. Similarly, if you lose something, you can
                      leave a note with the staff so they can contact you if
                      your item is found.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="MARKETPLACE VENDORS"
                    id="marketplace_vendors"
                  >
                    <BodyM className="item-text">
                      If you want to participate as a marketplace vendor, please
                      send email at{' '}
                      <a href="mailto:info@butikfestival.com">
                        info@butikfestival.com
                      </a>
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="PARKING" id="parking">
                    <BodyM className="item-text">
                      Parking is located close to the main entrance, a short
                      walk from the camping and festival area. You need a
                      parking ticket to park there.
                    </BodyM>
                    <BodyM className="item-text">
                      Parking tickets are available as a pre-order on our{' '}
                      <a href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0/tickets">
                        webshop
                      </a>{' '}
                      or on the spot.
                    </BodyM>
                    <BodyM className="item-text">
                      Parking fee (full festival):
                    </BodyM>
                    <ul>
                      <li>preorder: 30€</li>
                      <li>on site: 40€</li>
                    </ul>
                    <BodyM className="item-text">
                      For campervans there is special parking provided closer to
                      the festival area - see{' '}
                      <a onClick={() => scrollTo('#campervans')}>CAMPERVANS</a>.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="PETS" id="pets">
                    <BodyM className="item-text">
                      Dogs and other animals are NOT permitted in the festival
                      or campsite area. Anyone who tries to disobey this rule
                      will be removed from a festival area with revoked access,
                      without any further explanation. Please behave responsibly
                      towards your animal companion and leave them at home (or
                      in any other dog hotel or care, that will provide them
                      with the care they need).
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="PRESS" id="press">
                    <BodyM className="item-text">
                      If you want a press accreditation please send email at{' '}
                      <a href="mailto:info@butikfestival.com">
                        info@butikfestival.com
                      </a>
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="PROHIBITED OBJECTS" id="prohibited_objects">
                    <BodyM className="item-text">
                      We kindly ask that you leave the following items at home
                      as they&#39;re strictly not allowed on the
                      festival/camping site: objects that could be used as
                      projectiles, knives, weapons, or any items that could
                      potentially be used as a weapon. These items will not,
                      under any circumstances, be permitted into the festival
                      area. Any found items will be confiscated.
                    </BodyM>
                    <BodyM className="item-text">
                      Please note that nitrous oxide (laughing gas) is
                      prohibited at Butik. Its use disrupts others' enjoyment,
                      and violation of this rule will result in removal of your
                      wristband and dismissal from the festival.
                    </BodyM>
                    <BodyM className="item-text">
                      However, we understand that basic items like cutlery are
                      essential for those camping with us. You&#39;re absolutely
                      welcome to bring these to the campsite.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="RISK OF PARTICIPATION"
                    id="risk_of_participation"
                  >
                    <BodyM className="item-text">
                      Every visitor participates in the festival at his own
                      risk. Besides, every visitor is obliged to take action for
                      the guarantee of his own safety. The visitor is also
                      responsible for his property. The promoter, Butik Ent.
                      d.o.o., is not liable for any lost or damaged property
                      whatsoever. The promoter is also not liable for violations
                      of the Slovenian law, thievery, injuries, or damages by
                      third parties that happen in course of the festival.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SECURITY" id="security">
                    <BodyM className="item-text">
                      Please follow the instructions of the security personnel
                      and act responsibly and respectfully so everybody will
                      feel comfortable.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SEARCH POLICY" id="search_policy">
                    <BodyM className="item-text">
                      To ensure the safety of all attendees and staff, our
                      security team will conduct bag checks at the festival
                      entrance. Individuals found carrying prohibited items will
                      have those items confiscated.
                    </BodyM>
                    <BodyM className="item-text">
                      Your cooperation helps maintain a safe and enjoyable
                      festival experience for everyone.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SHUTTLE SERVICE" id="shuttle_service">
                    <BodyM className="item-text">
                      Our partner Markun Shuttle is offering a shuttle service
                      from nearby airports (Venice, Trieste, Ljubljana).
                    </BodyM>
                    <BodyM className="item-text">
                      For more information please{' '}
                      <a
                        href="https://www.markun-shuttle.com/en/2024/01/31/butik-festival-shuttle-2025"
                        target="_blank"
                      >
                        visit their website
                      </a>
                      . For everything related to shuttle service please contact
                      Markun Shuttle directly via email{' '}
                      <a href="mailto:markun.shuttle@gmail.com">
                        markun.shuttle@gmail.com
                      </a>
                      .
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SUPERMARKET" id="supermarket">
                    <BodyM className="item-text">
                      For all your festival essentials, there are a few
                      supermarkets that are just a short distance away from our
                      venue. It&#39;s a pleasant 15-minute walk.
                    </BodyM>
                    <BodyM className="item-text">
                      Please note that in Slovenia, all supermarkets and shops
                      close on Sundays.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SUSTAINABILITY" id="sustainability">
                    <BodyM className="item-text">
                      Our festival site is probably the most spectacular
                      location in Europe. It takes place in beautiful nature and
                      is impacting the life of the small local community. This
                      is why we want to do everything we can to respect nature
                      and locals. Thank you for your cooperation. To read more
                      about sustainability at Butik{' '}
                      <Link to="/sustainability">click here</Link>.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="SWIMMING" id="swimming">
                    <BodyM className="item-text">
                      Swimming in the river is allowed, but everyone is doing it
                      at their own risk. For your safety, we provide a
                      professional lifeguard who is stationed at the main
                      festival beach area throughout the festival. However,
                      it&#39;s important to note that swimming during the night
                      is strictly prohibited. We kindly ask all attendees to
                      respect and adhere to this rule for everyone's safety.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TAXI" id="taxi">
                    <BodyM className="item-text">
                      There will be two taxis available at the time of the
                      festival, but please be aware that wait times can be long
                      during peak hours. We recommend not relying solely on
                      taxis for transport to and from your accommodation.
                      Instead, consider staying within walking distance or in
                      the festival campsite.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TENT RENT" id="tent_rent">
                    <BodyM className="item-text">
                      We have a rent-a-tent option at Butik. It includes a basic
                      camping tent with two self-inflating mattresses—no
                      glamping, bell tents, or mobile homes.
                    </BodyM>
                    <BodyM className="item-text">
                      The tents are already set up in a shaded area when you
                      arrive and there's no need to fold up the tent after the
                      festival. We require a €50 deposit to secure your tent,
                      which will be returned to you upon departure, provided the
                      tent is left undamaged.
                    </BodyM>
                    <BodyM className="item-text">
                      To ensure a smooth process, here's a step-by-step guide on
                      how things work at the festival:
                    </BodyM>
                    <ul>
                      <li>
                        Upon arrival at the festival, proceed to the info point.
                      </li>
                      <li>Show your rent-a-tent ticket to the staff.</li>
                      <li>
                        Inform the rent-a-tent team of your expected departure
                        time.
                      </li>
                      <li>Pay the €50 deposit with your festival paycard.</li>
                      <li>
                        A member of our rent-a-tent team will escort you to your
                        tent.
                      </li>
                      <li>
                        When you're ready to depart, go to the info point.
                      </li>
                      <li>
                        Our rent-a-tent team will accompany you to check the
                        condition of the tent.
                      </li>
                      <li>
                        If the tent is undamaged, you can retrieve your deposit
                        at the info point.
                      </li>
                    </ul>
                    <BodyM className="item-text">
                      The price of 150€ includes:
                    </BodyM>
                    <ul>
                      <li>A Quechua tent (180 x 210 cm) </li>
                      <li>2 self-inflatable mats</li>
                    </ul>
                    <BodyM className="item-text">
                      Please remember to bring your own sleeping bag, as the
                      nights can get quite chilly.
                    </BodyM>
                    <BodyM className="item-text">
                      Please note that this service is only available for
                      pre-order and will not be an option during the festival.
                      The number of available tents is limited.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TICKETS" id="tickets">
                    <BodyM className="item-text">
                      Tickets for Butik Festival 2025 will go on sale
                      exclusively for past attendees on November 6. The
                      remaining tickets will be available to the public on
                      November 8. Only full festival tickets are sold, and all
                      tickets include camping and the festival tax. Early
                      arrival (Wednesday, day 0) is not included in the ticket.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TICKET REFUND" id="ticket_refund">
                    <BodyM className="item-text">
                      We do not offer ticket refunds, however, you can always
                      sell your ticket through our official resale platform.
                    </BodyM>
                    <BodyM className="item-text">
                      For resale check{' '}
                      <a onClick={() => scrollTo('#ticket_resale')}>
                        ticket resale
                      </a>
                      .
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="TICKET TERMS & CONDITIONS"
                    id="ticket_terms_and_conditions"
                  >
                    <BodyM className="item-text">
                      <a
                        href="https://butikfestival.com/privacy-policy#ticket-terms-conditions"
                        target="_blank"
                      >
                        Click here
                      </a>{' '}
                      to read ticket terms and conditions.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TICKET RESALE" id="ticket_resale">
                    <BodyM className="item-text">
                      We've created an official ticket reselling platform where
                      you can sell and buy your Butik tickets safely. It's the
                      one and only 100% secure way to purchase your festival
                      tickets if the primary sales are sold out.
                    </BodyM>
                    <BodyM className="item-text">
                      On our official reselling platform, when you purchase a
                      ticket, you receive a new one with a unique barcode, and
                      the seller's ticket is immediately invalidated or 'burnt'.
                      This means you won't need to worry about anyone else
                      having the same ticket as yours - your entry to the
                      festival is assured!
                    </BodyM>
                    <BodyM className="item-text">
                      Check out if there are any available tickets on our
                      official resale platform:{' '}
                      <a
                        href="https://resell.seetickets.com/butik-2025/"
                        target="_blank"
                      >
                        click here
                      </a>
                      .
                    </BodyM>
                    <BodyM className="item-text">
                      Please beware of ticket offers from random individuals
                      posting in Instagram and Facebook comments. Most of the
                      time, these offers are scams. This is an organized
                      activity, and each year we record several cases.
                    </BodyM>
                    <BodyM className="item-text">
                      Remember, we don't have an official partnership with
                      third-party platforms like Ticketswap, so buying tickets
                      from such places is not 100% safe.
                    </BodyM>
                  </Dropdown>
                  <Dropdown
                    title="TICKET PERSONALISATION"
                    id="ticket_personalisation"
                  >
                    <BodyM className="item-text">
                      We understand that ticket arrangements can change and
                      sometimes tickets get passed on to others. As long as you
                      have a valid ticket, you're good to go, regardless of the
                      name it bears. We don't check if the name on your ticket
                      matches your ID at the box office, as it's the unique code
                      on the ticket that is key, not the name.
                    </BodyM>
                    <BodyM className="item-text">
                      If you've purchased your ticket from a friend or through
                      our official resale webpage, rest assured, your ticket is
                      still valid.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="TIMETABLE" id="timetable">
                    <BodyM className="item-text">
                      Our festival's exact timetable will be available once the
                      festival gets closer, allowing you to plan your festival
                      experience effectively. Here's a general overview of the
                      music program for each day:
                    </BodyM>
                    <BodyM className="item-text">
                      Wednesday: 16:00 - 01:00
                      <br />
                      Thursday: 14:00 - 01:00
                      <br />
                      Friday: 12:00 - 07:00
                      <br />
                      Saturday: 10:00 - 07:00
                      <br />
                      Sunday: 07:00 - 19:00
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="VOLUNTEERS" id="volunteers">
                    <BodyM className="item-text">
                      We do not offer volunteer positions.
                    </BodyM>
                  </Dropdown>
                  <Dropdown title="WATER" id="water">
                    <BodyM className="item-text">
                      At the festival and camp area, running clean water is
                      provided free of charge. Please look for the sign that the
                      water is drinkable before you refill your own bottles (the
                      water in Slovenia / Tolmin is very clean, however not all
                      pipes are officially checked before the festival).
                    </BodyM>
                  </Dropdown>
                </div>
              </div>
            </Controller>
            <Footer isReverse={isReverse} />
          </div>
        </FestivalAtoZStyled>
      </PageLayout>
    </>
  )
}

export default FestivalAToZ
